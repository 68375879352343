<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="true"
                :showAddNew="false"
                :title="'PHC Update'"
            />
        </div>
        
        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="getOPDBillings"
            >

              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                  <label for="colFormLabel" class="col-form-label">Registration Status: </label>
                  <v-select
                      v-model="completionStatus" class="w-100" 
                      placeholder="Registration Status"
                      :options="statuses" label="name"
                      :reduce="(name) => name.id"
                  />
              </div>

              <div class="col-12 col-sm-6 col-md-5 col-lg-4">
                <label for="colFormLabel" class="col-form-label">Patient</label>
                <AsyncSelect
                  placeholder="Patient Name, ID, Mobile No"
                  v-model="patient"
                  :api-service="fetchContactProfiles"
                  :additional-query="additionalQuery"
                  :format-label="formatPatientLabel"
                />
              </div>

              <div class="col-12 col-sm-5 col-md-5 col-lg-2">
                <label for="colFormLabel" class="col-form-label">Patient ID</label>
                <vField
                  v-model="serialNo"
                  name="serial"
                  type="number"
                  class="form-control"
                  placeholder="Patient ID"
                  @keyup="getOPDBillings"
                />
              </div>
            </DateQuerySetter>
        </div>

        <div class="col-12 px-2 mt-2">

            <ListTable 
              :bills="bills"
              :completionStatus="completionStatus"
            />

            <div class="px-2 bottom-0 mt-2">
                <Pagination
                    :offset="offset"
                    @onPageChange="onPageChange"
                    ref="pagination"
                />
            </div>
        </div>

        <Loader v-if="loading"/>
    </div>
</template>

<script setup>
    import TitleButton from '@/components/atom/TitleButton.vue';
    import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
    import ListTable from '@/components/molecule/company/hospital/OpdPrescriptionPhcListTable.vue';
    import {useRoute, useRouter} from "vue-router";
    import handleHospitalBilling from "@/services/modules/hospital/billing";
    import {computed, inject, onMounted, ref} from "vue";
    import Loader from "@/components/atom/LoaderComponent.vue";
    import Pagination from "@/components/atom/Pagination.vue";
    import {useStore} from "vuex";
    import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
    import handleContact from "@/services/modules/contact";
    import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";

    const $route = useRoute();
    const $router = useRouter();
    const $store = useStore();

    const showError = inject('showError');
    const showSuccess = inject('showSuccess');
    const {fetchOPDBillings} = handleHospitalBilling();
    const {fetchContactProfiles} = handleContact()
    const {formatPatientLabel} = useAsyncDropdownHelper()

    const offset = ref(10);
    const loading = ref(false);
    const bills = ref([])
    const keyword = ref('')
    const doctor_id = ref(null)
    const status = ref(null)
    const patient = ref(null)
    const serialNo = ref(null)
    const completionStatus = ref('pending');
    const statuses = ref([
      {id: 'pending', name: 'Pending'},
      {id: 'completed', name: 'Completed'}
    ])

    const additionalQuery = {
      type: "patient"
    }

    const start = computed(() => $route.query.start);
    const end = computed(() => $route.query.end);
    const page = computed(() => $route.query.page);
    const companyId = computed(() => $route.params.companyId);
    
    onMounted(() => {
        getOPDBillings()
    })

    const setPaginationData = (data) => {
        $store.commit('setPaginationData', data)
    };

    const onPageChange = async (page) => {
        let routeQuery = Object.assign({}, $route.query)
        routeQuery.page = page
        await $router.push({path: $route.path, query: routeQuery})
        await getOPDBillings()
    }

    const setPagination = (data) => {
        setPaginationData({
          records: data.total,
          perPage: data.per_page,
          currentPage: data.current_page,
        })
    }

    const getQuery = () => {
        let query = '?company_id=' + companyId.value
        if (!start && !end) return query
        if (start) query += '&start_date=' + start.value
        if (end) query += '&end_date=' + end.value
        query += '&offset=' + offset.value
        if (page) query += '&page=' + page.value
        if (keyword.value) query += '&q=' + keyword.value
        if (doctor_id.value) query += '&doctor_id=' + doctor_id.value
        if (patient.value) query += '&patient_id=' + patient.value.id
        if (status.value) query += '&status=' + status.value
        if (serialNo.value) query += '&serial_no=' + serialNo.value
        if (completionStatus.value) query += '&completion_status=' + completionStatus.value
        return query
    };

    async function getOPDBillings() {
      loading.value = true;
      try {
        const res = await fetchOPDBillings(getQuery());
        if (!res.status) {
          bills.value = []
          return $store.commit("resetPagination")
        }
        bills.value = res.data.data;
        if (bills.value.length > 0) {
          setPagination(res.data)
        }
      } catch (err) {
        if (err.response) {
          showError(err.response.message)
        }
      } finally {
        loading.value = false;
      }
    }
</script>